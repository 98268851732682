<template>
  <div>
    <div class="newHead clearfix">
      <router-link :to="{name:'home'}" class="logo">
        <svg-icon icon-class="zxlogo" />
      </router-link>
      <h3>电子印章申请流程</h3>
      <div class="loginOut">
        <el-button v-if="$store.state.token" class="button" @click="$router.push({name:'personHome'})" type="primary">个人中心</el-button>
        <el-button class="button" v-else @click="$bus.$emit('cgLoginShow', true)" type="primary">登录</el-button>
      </div>
    </div>
    <div class="UkeyBackground">
      <div>
        <h5>使用豸信电子印章，实现无纸化签约，整个流程快速便捷低成本</h5>
        <el-button @click="verify('certApply')" type="primary">购买Ukey电子印章</el-button>
      </div>
    </div>
    <div class="applicationProcess">
      <h2>申请电子印章操作流程</h2>
      <div class="processIntro clearfix">
        <ul>
          <li v-for="(item,index) in process" :key='index' @click="stopTimer(index)" :class="{'active':index+1==processActive}"><i>{{item.order}}</i>{{item.name}}</li>
        </ul>
        <div class="introImg">
          <el-carousel :indicator-position="processActive===3?'outside':'none'" interval="2000" :loop="true">
            <el-carousel-item v-for="(item,index) in process[processActive-1].img" :key='index'>
              <img :src="require(`@/assets/image/productCenter/operationProcess/process${item}.png`)" alt="操作流程">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="clientInstructions">
      <h2>电子印章配合签章工具使用说明</h2>
      <dl>
        <dt><i>步骤一</i>下载“豸信CA签章客户端”软件安装包</dt>
        <dd>进入<em>豸信CA官网-帮助与下载-下载专区-驱动下载</em>，点击“豸信CA签章客户端”进行下载</dd>
        <dd><img src="@/assets/image/productCenter/operationProcess/step1.png" alt="步骤icon"></dd>
      </dl>
      <dl>
        <dt><i>步骤二</i>安装“豸信CA签章客户端”软件</dt>
        <dd>安装前<em>请先退出杀毒软件，</em>如有拦截请选择<em>“允许”或“允许程序所有操作”</em>以防止软件被禁用；</dd>
      </dl>
      <dl>
        <dt><i>步骤三</i>安装成功，会在桌面自动生成“豸信CA签章客户端”图标，启动程序，插入Ukey进行使用</dt>
        <dd>使用“<em>豸信CA签章客户端</em>”进行签署，请务必<em>插入Ukey</em>（类似于银行U盾，电子印章的载体）才可以进行签署；<em></em></dd>
        <dd><em>拖动印章到指定位置</em>，签署成功之后保存文件即可</dd>
        <dd><img src="@/assets/image/productCenter/operationProcess/step2.png" alt="步骤icon"></dd>
      </dl>
    </div>
  </div>
</template>
 
<script>
export default {
  data () {
    return {
      process: [
        {
          order: 1,
          name: '登录',
          img: [1]
        },
        {
          order: 2,
          name: '个人实名',
          img: [2]
        },
        {
          order: 3,
          name: '提交证书申请',
          img: [3, 4]
        },
        {
          order: 4,
          name: '豸信审核',
          img: [5]
        },
        {
          order: 5,
          name: '在线付款',
          img: [6]
        },
        {
          order: 6,
          name: '制作并邮寄',
          img: [7]
        }
      ],
      processActive: 1,
      timer: ''
    }
  },
  mounted () {
    this.openTimer()
  },
  methods: {
    openTimer () {
      clearInterval(this.timer)
      this.timer = ''
      this.timer = setInterval(() => {
        if (this.processActive === 6) {
          this.processActive = 1
        } else {
          this.processActive += 1
        }
      }, 4000)
    },
    stopTimer (index) {
      this.processActive = index + 1
      clearInterval(this.timer)
      this.timer = ''
    },
    verify (name) {
      this.$bus.$off('afterLogin')
      this.$bus.$on('afterLogin', () => {
        this.verify(name)
      })
      this.$api.getAccountInfo().then((res) => {
        if (res.data.verified) {
          let routeData = this.$router.resolve({ name })
          window.open(routeData.href, '_blank')
        } else {
          this.$confirm('当前账号未完成管理员实名认证，实名之后才可办理业务。', '提示', {
            confirmButtonText: '实名认证',
            cancelButtonText: '暂不认证',
            type: 'warning'
          }).then(() => {
            this.$router.push({ path: '/personHome' })
          }).catch(() => {

          });
        }
      })
    },
  },
  destroyed () {
    clearInterval(this.timer)
    this.timer = ''
  }
}
</script>

<style lang="scss" scoped>
.newHead {
  width: 1300px;
  height: 60px;
  margin: 0 auto;
  h3 {
    font-size: 14px;
    color: #303b50;
    line-height: 60px;
    font-weight: normal;
    float: left;
  }
}
.logo {
  margin-right: 36px;
  color: #1c67f3;
  font-size: 44px;
  line-height: 60px;
  cursor: pointer;
  float: left;
}
.loginOut {
  float: right;
  padding-top: 10px;
}
.UkeyBackground {
  height: 244px;
  background: url('~@/assets/image/productCenter/operationProcess/procedure.png')
    center center no-repeat;
  background-size: 100% 100%;
  > div {
    width: 1300px;
    padding: 82px 10px 0;
    margin: 0 auto;
  }
  h5 {
    font-size: 18px;
    color: #303b50;
    line-height: 24px;
    font-weight: normal;
    margin-bottom: 42px;
  }
}
.applicationProcess {
  width: 1300px;
  margin: 0 auto;
  h2 {
    font-size: 40px;
    color: #303b50;
    line-height: 53px;
    margin-top: 194px;
    margin-bottom: 115px;
    text-align: center;
  }
}
.processIntro {
  ul {
    float: left;
    margin-right: 32px;
  }
  li {
    width: 224px;
    height: 60px;
    padding-left: 32px;
    background: #ffffff;
    border-radius: 1px;
    border: 1px solid #d3d5db;
    font-size: 16px;
    color: #303b50;
    line-height: 60px;
    margin-bottom: 32px;
    list-style: none;
    cursor: pointer;
  }
  i {
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: #d3d5db;
    font-size: 16px;
    color: #303b50;
    line-height: 32px;
    margin-right: 24px;
    text-align: center;
  }
  .active {
    background: #1c67f3;
    border-radius: 4px;
    color: #fff;
    i {
      background: #ffffff;
    }
  }
}
.introImg {
  width: 1044px;
  height: 510px;
  float: right;
  position: relative;
  .el-carousel {
    position: initial;
    box-shadow: 0px 8px 24px 0px rgba(133, 167, 222, 0.29);
    position: static;
  }
  ::v-deep .el-carousel__container {
    width: 1044px;
    height: 510px;
  }
  ::v-deep .el-carousel__indicators--outside {
    position: absolute;
    bottom: -33px;
    left: 50%;
    transform: translateX(-50%);
  }
  ::v-deep .el-carousel__indicator--horizontal {
    width: 10px;
    height: 10px;
    background: #d3d5db;
    border-radius: 5px;
    padding: 0;
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
    button {
      width: 0;
      height: 0;
    }
  }
  ::v-deep .el-carousel__indicator--horizontal.is-active {
    background: #0059ff;
  }
  img {
    width: 100%;
  }
}
.clientInstructions {
  width: 1300px;
  margin: 0 auto 126px;
  h2 {
    font-size: 40px;
    color: #303b50;
    line-height: 53px;
    margin: 177px 0 109px;
    text-align: center;
  }
  dl {
    margin-bottom: 72px;
  }
  dt {
    font-size: 16px;
    font-weight: bold;
    color: #303b50;
    line-height: 52px;
    margin-bottom: 40px;
  }
  i {
    display: inline-block;
    width: 138px;
    height: 52px;
    padding-left: 29px;
    background: url('~@/assets/image/productCenter/operationProcess/arrowhead.png')
      left top no-repeat;
    background-size: 100% 100%;
    margin-right: 24px;
  }
  dd {
    font-size: 16px;
    color: #303b50;
    line-height: 21px;
    margin-bottom: 24px;
    &:last-child {
      margin: 0;
    }
  }
  em {
    color: #0059ff;
  }
  img {
    width: 1299px;
    display: inline-block;
  }
}
</style>